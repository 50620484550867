import './App.css';
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {useState} from "react";
import EXIF from "exif-js";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [28, 46],
    iconAnchor: [17, 46],
});

L.Marker.prototype.options.icon = DefaultIcon;

function App() {
    function LocationMarker() {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
            click() {
                map.locate()
            },
            locationfound(e) {
                setPosition(e.latlng)
                map.flyTo(e.latlng, map.getZoom())
            },
        })

        return position === null ? null : (
            <Marker position={position}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }


    const [markers, setMarkers] = useState([])
    const [filesImported, setFilesImported] = useState(0)
    const [filesToImport, setFilesToImport] = useState(0)

    const LocationFinderDummy = () => {
        const [position, setPosition] = useState(null)
        const [areas, setAreas] = useState([])

        const map = useMapEvents({
            click(e) {
                fetch('/geocode?lat=' + e.latlng.lat + '&lon=' + e.latlng.lng)
                    .then(response => response.json())
                    .then(data => setAreas(data.areas));
                console.log(e.latlng);
                setPosition(e.latlng)
            },
        });
        return position === null ? null : (
            <Marker position={position}>
                <Popup>You are here:<br/>
                    {areas.map((a) => {
                        return a.name_en + '<br/>'
                    })}

                </Popup>
            </Marker>
        )
    };

    const upLoadFileFolderFunc = (e) => {

        function ConvertDMSToDD(degrees, minutes, seconds, direction) {
            var dd = degrees + (minutes / 60) + (seconds / 360000);
            if (direction == "S" || direction == "W") {
                dd = dd * -1;
            }
            return dd;
        }

        const readImage = async (file) => {
            return new Promise((resolve, reject) => {
                // Check if the file is an image.
                if (file.type && !file.type.startsWith('image/')) {
                    setFilesImported(filesImported => filesImported + 1)
                    console.log('File is not an image.', file.type, file);
                    return reject('File is not an image: ' + file.type);
                }

                EXIF.getData(file, function () {
                    setFilesImported(filesImported => filesImported + 1)
                    let exifData = this;
                    if (exifData.exifdata && exifData.exifdata.GPSLatitude) {
                        let latDegree = exifData.exifdata.GPSLatitude[0].numerator;
                        let latMinute = exifData.exifdata.GPSLatitude[1].numerator;
                        let latSecond = exifData.exifdata.GPSLatitude[2].numerator;
                        let latDirection = exifData.exifdata.GPSLatitudeRef;

                        let latFinal = ConvertDMSToDD(latDegree, latMinute, latSecond, latDirection);

                        let lonDegree = exifData.exifdata.GPSLongitude[0].numerator;
                        let lonMinute = exifData.exifdata.GPSLongitude[1].numerator;
                        let lonSecond = exifData.exifdata.GPSLongitude[2].numerator;
                        let lonDirection = exifData.exifdata.GPSLongitudeRef;

                        let lonFinal = ConvertDMSToDD(lonDegree, lonMinute, lonSecond, lonDirection);
                        console.log(latFinal, lonFinal)
                        setMarkers(markers => [...markers, {latitude: latFinal, longitude: lonFinal}])
                        return resolve({latitude: latFinal, longitude: lonFinal});
                    } else {
                        console.log("No EXIF data found in image '" + file.name + "'.");

                        return reject('No EXIF data found in image.');
                    }
                });
            })
        }


        e.preventDefault();
        let fileitemsList = e.target.files;
        setFilesToImport(fileitemsList.length)

        let promises = [];

        for (let i = 0; i < fileitemsList.length; i++) {
            let file = fileitemsList[i];
            promises.push(readImage(file))
        }

        Promise.all(promises)
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    return (
        <>
            <span>{filesImported} / {filesToImport}</span><br/>
            <input type="file" webkitdirectory="" directory="" multiple="" onChange={upLoadFileFolderFunc}></input>
            <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationFinderDummy/>
                <Marker position={[51.505, -0.09]}>
                    <Popup>
                        A pretty CSS3 popup. <br/> Easily customizable.
                    </Popup>
                </Marker>
                {markers.map((marker) => (
                    <Marker position={[marker.latitude, marker.longitude]}/>
                ))}
            </MapContainer>
        </>
    );
}

export default App;
